import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class FirmaComponent implements OnInit {
  firma: any;
  user: any;
  error: any;

  constructor(private route: ActivatedRoute, private service: SharedService) {}

  ngOnInit(): void {
    this.getFirma();
    this.getUser();
  }

  // tslint:disable-next-line: typedef
  getFirma() {
    this.service.getFirma().subscribe((firma: any) => {
      this.firma = firma;
    });
  }

  // tslint:disable-next-line: typedef
  getUser() {
  this.service.getUser().subscribe((data: any) => {
    this.user = data != null ? data : null;
  });
}
}
