import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import urls from 'src/assets/urls.json';

const headers = {
  'Content-Type': 'application/json',
};

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: typedef
  getRegistration(key: any) {
    const body = {
      action: 'registration',
      startkey: key,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.registrierung, body, { headers: headers });
  }

  // tslint:disable-next-line: typedef
  getGliederung(key: any) {
    const body = {
      action: 'getGliederung',
      startkey: key,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.registrierung, body, { headers: headers });
  }

    // tslint:disable-next-line: typedef
    getFirma(key: any) {
      const body = {
        action: 'getFirma',
        startkey: key,
      };
      // tslint:disable-next-line: object-literal-shorthand
      return this.http.post(urls.registrierung, body, { headers: headers });
    }

  // tslint:disable-next-line: typedef
  closeRegistration(
    key: any,
    password: any,
    mitarbeitende: any,
    ersthelfende: any
  ) {
    const body = {
      action: 'finishRegistration',
      startkey: key,
      passwort: password,
      anzahlMA: mitarbeitende,
      anzahlEH: ersthelfende,
    };
    // tslint:disable-next-line: object-literal-shorthand
    return this.http.post(urls.registrierung, body, { headers: headers });
  }
}
