


<div class="container align-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" [@fadeIn]>
  <div class="row">
    <div class="col fs-4 fw-bold"> Ersthelfendeübersicht</div>
  </div>
  <div class="row pb-4">
    <div class="col"> Hier sehen Sie eine Übersicht über Ihre Ersthelfenden im Unternehmen. Diese haben einen gültigen Erste Hilfe Kurs absolviert. Sie können zudem sich die Bescheinigung für Ihre Mitarbeitende herunterladen.</div>
  </div>
<div class="columns">
<div class="column is-two-thirds">
  <div class="d-flex flex-row">
    <input [(ngModel)]="PersonalFilter" class="form-control"
    (keyup)="FilterFn()" placeholder="Filter">
  </div>
  <div class="table-responsive">
<table class="table is-hoverable">
    <thead>
        <tr>

<th scope="col">
<div>
            Nachname
            <a (click)="sortResult('Nachname',true)">↑
            </a><!-- eslint-disable --><a (click)="sortResult('Nachname',false)"> ↓</a>
         </div>

</th>
<th scope="col">
  <div>
  Vorname
    <a (click)="sortResult('Vorname',true)">↑
    </a><!-- eslint-disable --><a (click)="sortResult('Vorname',false)"> ↓</a>
</div>
</th> 
<th> Ersthelfende bis
</th>

            <th class="text-center">Ansicht</th>
            <th class="text-center">Bescheinigung</th>
        </tr>
    </thead>
    <tbody>
      <tr class="clickable" *ngFor="let dataItem of PersonalList"  >
        <td >{{dataItem.Nachname}} </td>
        <td>{{dataItem.Vorname}} </td>
           <td>{{convertDate(dataItem.Expired)}}</td> 
           <td class="text-center">
            <button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/personal/mitarbeitende/', dataItem.TnID]">
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
           <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
         </svg>
            </button>
</td>
           <td class="text-center">
            <button type="button" class="btn btn-outline-secondary btn-sm"  (click) = "goToLink(dataItem.KursCode, dataItem.TeilnahmeCode
            , dataItem.ValidierungsCode)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
          </svg> 
        </button></td>
       </tr>
    </tbody>
</table>
</div>
</div>
</div>
</div>
