
<div class="containeralign-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" >
    <div class="columns" *ngIf="interneKursListe?.length > 0">
    <div class="column is-two-thirds">
    <table class="table is-hoverable">
        <thead>
            <tr>
                <th>
                  KursCode
  
    </th>
                <th>
                  Kurstitel
    </th>
    <th> Datum
  
        </thead>
        <tbody>
            <tr class="clickable" *ngFor="let dataItem of interneKursListe; index as i">
                <td>{{dataItem.KursCode}}</td>
                <td>{{dataItem.Kursname}}</td>
                <td>{{convertDate(dataItem.Datum)}}</td>
    
            </tr>
        </tbody>
    </table>
    </div>
  
    </div>

  
  
    <div class="row fw-bold" *ngIf=" interneKursListe?.length  < 1"> 
      <div class="col">Es gibt noch keine Kurse.</div></div>    </div>