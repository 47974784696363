import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-anfrage',
  templateUrl: './anfrage.component.html',
  styleUrls: ['./anfrage.component.css'],
})
export class AnfrageComponent implements OnInit {
  myform!: UntypedFormGroup;

  constructor(
    private router: Router,

  ) {}

  ngOnInit(): void {
    this.myform = new UntypedFormGroup({
      kursort: new UntypedFormControl(''),
      anzahl: new UntypedFormControl(''),
      wunschdatum: new UntypedFormControl(''),
      wunschtage: new UntypedFormControl(''),
      raum: new UntypedFormControl(''),
      vorhandeneAusstattung: new UntypedFormControl(''),
      raumgroesse: new UntypedFormControl(''),
    });
  }
  // tslint:disable-next-line: typedef
  get authentification() {
    return localStorage.getItem('Token');
  }
}
