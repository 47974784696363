<div
  class="container align-items-center justify-content-center p-3 shadow p-3"
  style="border-radius: 1rem"
  [@fadeIn]
>
  <div class="row"><div class="col fs-3 fw-bold">Detailansicht</div></div>
  <form [formGroup]="profilForm" (ngSubmit)="updatePersonal(profilForm)">
    <div class="row">
      <div class="col text-end">
        <button
          type="button"
          style="width: 150px"
          class="btn btn-outline-secondary"
          (click)="edit()"
          *ngIf="!editActive"
        >
          Daten bearbeiten
        </button>
        <button
          type="submit"
          class="btn btn-outline-secondary"
          style="width: 150px"
          *ngIf="editActive"
        >
          Daten speichern
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col fs-5 fw-bold">Persönliche Daten</div>
        </div>
        <div class="row">
          <div class="col">Vorname</div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="vorname"
              id="vorname"
              formControlName="vorname"

            />
          </div>
        </div>
        <div class="row">
          <div class="col">Nachname</div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="nachname"
              id="nachname"
              formControlName="nachname"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            Geburtsdatum
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="TT.MM.JJJJ"
              aria-label="gebdatum"
              id="gebdatum"
              formControlName="geburtsdatum"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            Geschlecht
            <select
              class="form-select form-select-sm"
              aria-label="geschlecht"
              id="geschlecht"
              formControlName="geschlecht"
            >
              <option value="m">männlich</option>
              <option value="w">weiblich</option>
              <option value="d">divers</option>
              <option value="n">keine Angabe</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col fs-5 fw-bold">Adresse</div>
        </div>
        <div class="row">
          <div class="col">Straße</div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="strasse"
              id="strasse"
              formControlName="strasse"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">Postleitzahl</div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="plz"
              id="plz"
              formControlName="plz"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">Ort</div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="ort"
              id="ort"
              formControlName="ort"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col fs-5 fw-bold">Kontaktdaten</div>
        </div>
        <div class="row">
          <div class="col">
            E-Mail
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="email"
              id="email"
              formControlName="email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            Festnetz
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="festnetz"
              id="festnetz"
              formControlName="telefon"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            Mobil
            <input
              type="text"
              class="form-control form-control-sm"
              aria-label="mobil"
              id="mobil"
              formControlName="mobil"
            />
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row p-4 p-2">
    <div class="col p-2  shadow-sm">
      <div class="row">
        <div class="col fs-4 fw-bold ">Teilgenommene Kurse</div>
      </div>
      <div class="row">
        <div class="col"><app-zeige-teilgenommenekurse></app-zeige-teilgenommenekurse></div>
      </div>
      
    </div>

    <div class="col p-2 shadow-sm">
      <div class="row">
        <div class="col fs-4 fw-bold  ">Bevorstehende Kurse</div>
   
      </div>
      <div class="row">
        <div class="col">     <div class="col"><app-kurse></app-kurse></div></div>
      </div>
    </div>
  </div>

  <div class="row p-4">
    <div class="col">
      <button type="button" class="btn btn-outline-secondary" routerLink="..">
        Zurück
      </button>
    </div>
  </div>
</div>
