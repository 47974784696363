import { Component, OnInit } from '@angular/core';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';
import urls from 'src/assets/urls.json';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'AngularApp';
  msg: any;
  // tslint:disable-next-line: variable-name

  ipAddress = '';
  userAgent = '';
  firma: any;
  user: any;
  gliederung: any;
  loggedIn = false;

  constructor(
    private service: SharedService,
    public router: Router,
    private logService: LoginService,
    private http: HttpClient
  ) {}

  seconds: any;

  ngOnInit(): void {

    if (localStorage.getItem('key') !== null) {
      const token = JSON.stringify(localStorage.getItem('key'));
      if (this.service.isTokenExpired(token)) {
        this.logout();
      } else {
        this.loggedIn = true;
        this.router.navigate(['/home']);
        // this.getIPAddress();
        // this.userAgent = window.navigator.userAgent;
        this.getUser();
      }
    }
  }

  // tslint:disable-next-line: typedef
  getUser() {
    this.service.getGliederung().subscribe((data: any) => {
      this.gliederung = data != null ? data : null;
    });
    this.service.getUser().subscribe((data: any) => {
      this.user = data != null ? data : null;
    });
    this.service.getFirma().subscribe((data: any) => {
      this.firma = data != null ? data : null;
    });
  }

  // tslint:disable-next-line: typedef
  logout() {
    this.service.logOutAccesslog().subscribe(
      (data: any) => {}
    );
    localStorage.removeItem('key');
    this.loggedIn = false;
  }

  // tslint:disable-next-line: typedef
  getIPAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.ipAddress = res.ip;
      });
  }

  // tslint:disable-next-line: typedef
  openTeilnahme() {
    window.open(this.gliederung.OL_URL_Teilnahmebed, '_blank');
  }

  // tslint:disable-next-line: typedef
  openDatenschutz() {
    window.open(this.gliederung.OL_URL_Datenschutz, '_blank');
  }

  // tslint:disable-next-line: typedef
  openImpressum() {
    window.open(this.gliederung.OL_URL_Impressum, '_blank');
  }

  // tslint:disable-next-line: typedef
  openKIS() {
    window.open(urls.kis, '_blank');
  }
}