import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';
import urls from 'src/assets/urls.json';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class AuditComponent implements OnInit {
  constructor(
    private service: SharedService,
    private router: Router,
  ) {}

  PersonalList: any = [];
  personal: any;
  PersonalFilter = '';
  PersonalListWithoutFilter: any = [];

  ngOnInit(): void {
    this.refreshPersonalList();
  }

  // tslint:disable-next-line: typedef
  refreshPersonalList() {
    this.service
      .getPersonalListFirmenGueltigEH()
      .subscribe((listPersonal: any) => {
        this.PersonalList = listPersonal;
        this.PersonalListWithoutFilter = listPersonal;
      });
  }

  // tslint:disable-next-line: typedef
  FilterFn() {
    const PersonalNachnameFilter = this.PersonalFilter;
    const PersonalVornameFilter = this.PersonalFilter;

    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.filter((el: any) => {
      return (
        el.Nachname.toString()
          .toLowerCase()
          .includes(PersonalNachnameFilter.toString().trim().toLowerCase()) ||
        el.Vorname.toString()
          .toLowerCase()
          .includes(PersonalVornameFilter.toString().trim().toLowerCase())
      );
    });
  }

  // tslint:disable-next-line: typedef
  convertDate(date: string) {
    if (date !== '-' && date !== undefined) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return date;
    }
  }

  // tslint:disable-next-line: typedef
  sortResult(prop: any, asc: any) {
    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.sort(
      (a: any, b: any) => {
        if (asc) {
          return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
        } else {
          return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
        }
      }
    );
  }

  // tslint:disable-next-line: typedef
  goToLink(kursCode: string, teilnahmeCode: string, validierungsCode: string) {
    // tslint:disable-next-line: max-line-length
    const link =
      'https://downloads.kispro.de/tnbdownload.php?k=' +
      kursCode +
      '&t=' +
      teilnahmeCode +
      '&v=' +
      validierungsCode;

    window.open(link, '_blank');
  }
}
