<div
  class="container align-items-center justify-content-center p-3 shadow"
  style="border-radius: 1rem" 
  [@fadeIn]
  *ngIf="!success"
>
  <div class="row fs-3 fw-bold py-3">
    <div class="col text-center">Ihre Registrierung beim FirmenManager</div>
  </div>
  <div class="row mx-1 pb-2">
    Sehr geehrte/r {{ registration?.Vorname }} {{ registration?.Nachname }},
  </div>

  <div class="row mx-1 pb-2">
    Willkommen im FirmenManager. Der FirmenManager hilft Ihnen dabei einen
    Überblick über den Status der Ersthelfenden in Ihrem Unternehmen zu
    erhalten.
  </div>
  <div class="row mx-1 pb-5">
    Um Ihren Zugang zu aktivieren, vervollständigen Sie bitte Ihre Daten, um die
    Registrierung abzuschließen.
  </div>

  <form [formGroup]="angForm" (ngSubmit)="postdata(angForm)">
    <div class="row py-2"><strong>Ihre E-Mail Adresse: </strong></div>
    <div class="row pb-2">
      <div class="col">
        <div class="form-outline">
          <input
            type="email"
            class="form-control form-control-sm"
            name="email"
            autocomplete="email"
            placeholder="Login"
            formControlName="email"
          />
        </div>
      </div>
    </div>

    <div class="row py-2"><strong>Ihr neues Passwort: </strong></div>
    <div class="row pb-2">
      <div class="col">
        <div class="form-outline">
          <input
            type="password"
            class="form-control form-control-sm"
            name="login-password"
            autocomplete="current-password"
            placeholder="Passwort"
            formControlName="password1"
          />
        </div>
      </div>
    </div>

    <div class="row py-2"><strong>Passwort wiederholen: </strong></div>
    <div class="row pb-2">
      <div class="col">
        <div class="form-outline">
          <input
            type="password"
            class="form-control form-control-sm"
            name="login-password2"
            placeholder="Passwort wiederholen"
            formControlName="password2"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!validatePasswortForm" style="color: red; font-size: 12px;">
      <div class="col">
      Ihre Passwörter stimmen nicht überein.
    </div>
    </div>

    <div class="row py-2"><strong>Die Anzahl der Mitarbeitende:</strong></div>
    <div class="row pb-2">
      <div class="col">
        <div class="form-outline">
          <input
            type="number"
            class="form-control form-control-sm"
            name="mitarbeitende"
            placeholder="Anzahl Mitarbeitende"
            formControlName="mitarbeitende"
          />
        </div>
      </div>
    </div>

    <div class="row py-2">
      <strong>Die Anzahl der benötigten Ersthelfende:</strong>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-outline">
          <input
            type="number"
            class="form-control form-control-sm"
            name="login-username"
            placeholder="Anzahl Ersthelfende"
            formControlName="ersthelfende"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col"><button
        type="submit"
        class="btn btn-outline-secondary my-3"
        [disabled]="!angForm.valid || !validatePasswortForm"
        *ngIf="!spinner"
      >
        Registrierung abschließen
      </button></div>

        <div class="col py-3" *ngIf="spinner" [@fadeIn]>
          <div class="spinner-border" role="status" style="color: grey">
            <span class="visually-hidden">Loading...</span>
          </div>
      </div>
    </div>
    

  </form>
</div>


<div class="row justify-content-center pt-3"  *ngIf="success" [@fadeIn]> 
  <div class="col-12 col-md-8 col-lg-6 col-xl-5">
    <div class="card shadow-sm p-3" style="border-radius: 1rem">
      <div class="card-body p-3 text-center">
    <div class="row">
      <div class="col text-center fs-1 text-success">  &#10003;</div>
    
    </div>
<div class="row">
  <div class="col fw-bold fs-4 pb-2">Sie haben sich erfolgreich registriert.</div>

  <div class="row"> 
    <div class="col">
      Bitte nutzen Sie Ihre E-Mail Adresse und das von Ihnen gewählte Passwort als Login.
    </div>
  </div>
  <div class="row pt-3"> 
    <div class="col"> <button class="btn btn-outline-secondary" routerLink="login">Zur Anmeldung</button></div></div>
</div>  
</div>
    </div>
  </div>
</div>


<footer
  class="fixed-bottom footer mt-2"
  [@fadeIn]
  style="background-color: {{ gliederung?.OL_Footer_BGColor }};"
>
  <hr style="padding: 0px; margin: 0px" />
  <div
    class="row p-2 footer-text mx-2"
    style="color:{{ gliederung?.OL_Footer_FontColor }}; "
  >
    <div class="col">
      &copy; Copyright 2023
      <a
        class="mouse"
        (click)="openKIS()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
      >
        <u>KISpro</u></a
      >
    </div>
    <div class="col text-end">
      <a
        class="mouse"
        (click)="openImpressum()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
        >Impressum</a
      >
      |
      <a
        class="mouse"
        (click)="openDatenschutz()"
        style="color: {{ gliederung?.OL_Footer_FontColor }};"
        >Datenschutz</a
      >
    </div>
  </div>
</footer>

