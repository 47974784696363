import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import urls from 'src/assets/urls.json';
import * as CryptoJS from 'crypto-js';

const headersType = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  redirectUrl: string;

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  constructor(private httpClient: HttpClient) {}

  // tslint:disable-next-line: typedef
  public userlogin(username: any, password: any) {
    const hash = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex);
    const body = { user: username, password: hash };
    return this.httpClient.post(urls.APIUrllogin, body, {
      headers: headersType,
    });
  }


  // tslint:disable-next-line: typedef
  public logout() {
    localStorage.removeItem('key');
    const body = { action: 'logout'};
    return this.httpClient.post(urls.APIUrllogout, body, {
      headers: headersType,
    });
  }
}
