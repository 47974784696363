<div class="row">
  <div class="col">
    <div class="card shadow p-2" style="border-radius: 1rem">
      <div class="card-body p-3">
        <h2 class="fw-bold mb-0 p-3 text-center">Kontaktanfrage</h2>
        <div class="row py-1">
          <label for="vorname" class="col-form-label fw-bold">Vorname</label>
        </div>
        <div class="row">
          <input
            type="text"
            class="form-control form-control-sm"
            id="vorname"
            placeholder="Max"
          />
        </div>
        <div class="row py-1">
          <label for="nachname" class="col-form-label fw-bold">Nachname</label>
        </div>
        <div class="row">
          <input
            type="text"
            class="form-control form-control-sm"
            id="nachname"
            placeholder="Mustermann"
          />
        </div>
        <div class="row py-1">
          <label for="email" class="col-form-label fw-bold"
            >E-Mail-Adresse</label
          >
        </div>
        <div class="row">
          <input
            type="text"
            class="form-control form-control-sm"
            id="email"
            placeholder="Ihre Email Adresse"
          />
        </div>
        <div class="row py-1">
          <label for="nachricht" class="col-form-label fw-bold"
            >Ihre Nachricht</label
          >
        </div>
        <div class="row">
          <textarea
            class="form-control form-control-sm"
            id="nachricht"
            placeholder="Ihre Nachricht"
          ></textarea>
        </div>

        <div class="row p-3">
          <div class="col"></div>
          <div class="col text-end">
            <button
              type="submit"
              class="btn btn-outline-secondary btn-sm p-2"
              (click)="kontaktAnfrage()"
            >
              Anfrage versenden
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card shadow p-2" style="border-radius: 1rem">
      <div class="card-body p-3 m-3">
        <h2 class="fw-bold mb-0 p-3 text-center">Ihr Kontakt</h2>
        <div
          class="row pb-3"
          [innerText]="gliederung?.OL_Text_Ansprechpartner"
        ></div>
        <div class="row" [innerText]="gliederung?.OL_Anschrift_Name"></div>
        <div class="row">{{ gliederung?.OL_Anschrift_Strasse }}</div>
        <div class="row">
          {{ gliederung?.OL_Anschrift_PLZ }} {{ gliederung?.OL_Anschrift_Ort }}
        </div>
      </div>
    </div>
  </div>
</div>
