import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-personal',
  templateUrl: './add-edit-personal.component.html',
  styleUrls: ['./add-edit-personal.component.css'],
})
export class AddEditPersonalComponent implements OnInit {
  errors: any;
  Firma: any = [];

  constructor(
    private service: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditPersonalComponent>,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar,
    private router: Router,
  ) {}

  profilForm = new UntypedFormGroup({
    vorname: new UntypedFormControl('', [Validators.required]),
    nachname: new UntypedFormControl('', [Validators.required]),
    geschlecht: new UntypedFormControl(''),
    geburtsdatum: new UntypedFormControl('', [Validators.required]),
    strasse: new UntypedFormControl(''),
    plz: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    telefon: new UntypedFormControl(''),
    mobil: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
  });

  ngOnInit(): void {}

  addPersonal(form: UntypedFormGroup) {
    const val = {
      Name: form.value.nachname,
      Vorname: form.value.vorname,
      GebDat: form.value.geburtsdatum,
      Strasse: form.value.strasse,
      Ort: form.value.ort,
      PLZ: form.value.plz,
      TelFestnetz: form.value.telefon,
      TelMobil: form.value.mobil,
      Email: form.value.email,
      Geschlecht: form.value.geschlecht,
    };
    this.service.addTeilnehmer(val).subscribe((data: any) => {
      this.openSnackBar(data.message);
      
    });
    this.dialogRef.close();
  }

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date === null) {
      return '';
    } else {
      let [year, month, days] = date.split('.');
      return year + '-' + month + '-' + days;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Die Erstellung wurde abgebrochen.');
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5 * 1000,
      panelClass: ['warn-snackbar'],
    });
  }
}
