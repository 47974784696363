import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-zeige-kurse',
  templateUrl: './zeige-kurse.component.html',
  styleUrls: ['./zeige-kurse.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ZeigeKurseComponent implements OnInit {
  kurs: any;
  teilnehmende: any;
  lehrgangsort: any;
  dauer: any;
  error: any;

  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
  ) {}

  ngOnInit(): void {
    this.loadKurs();
  }

  // tslint:disable-next-line: typedef
  loadKurs() {
    const kursID = this.route.snapshot.paramMap.get('id');
    this.service.getKursDetailById(kursID).subscribe((data: any) => {
      this.kurs = data;
    });
    this.service.getTeilnehmendeKurs(kursID).subscribe((data: any) => {
      this.teilnehmende = data;
      console.log(data);
    });
    
    // this.service.getLehrgangsortDetailById(kursID).subscribe(
    //   (data) => {
    //     this.lehrgangsort = data;
    //   },
    //   (error) => (this.error = error)
    // );
  }


  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }

    // tslint:disable-next-line: typedef
    convertTime(time: string) {
      if(time != null){
      return time.substring(0, time.length - 3);
    }
      return '-';
    }

    // tslint:disable-next-line: typedef
    calcTime(start: any, end: any) {
      if (start != null && end != null) {
        const [hoursStart, minutesStart, secondsStart] = start.split(':');
        const [hoursEnd, minutesEnd, secondsEnd] = end.split(':');

        let stunden = Number(hoursEnd) - Number(hoursStart);
        let minuten = Number(minutesEnd) - Number(minutesStart);
        let counter = 0;
        if (minuten === 30 || minuten === -30)
        {
          counter = minuten === -30 ? 1 : 0;
          minuten = 5;
        }
        if ( counter === 1)
        {
          stunden = stunden - counter;
        }

        return stunden + ',' + minuten;
      } else {
        return;
      }
    }

}
