import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-zeige-teilgenommenekurse',
  templateUrl: './zeige-teilgenommenekurse.component.html',
  styleUrls: ['./zeige-teilgenommenekurse.component.css'],
})
export class ZeigeTeilgenommenekurseComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
  ) {}

  KursList: any = [];
  KursListWithoutFilter: any = [];
  ngOnInit(): void {
    this.refreshKursList();
  }

  // tslint:disable-next-line: typedef
  refreshKursList() {
    const personalID = this.route.snapshot.paramMap.get('id');
    this.service.getTeilnahmenByTeilnehmer(personalID).subscribe((data) => {
      this.KursList = data;
      this.KursListWithoutFilter = data;
    });
  }


  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    const [year, month, day] = date.split('-');
    return day + '.' + month + '.' + year;
  }

  // tslint:disable-next-line: typedef
  goToLink(kursCode: string, teilnahmeCode: string, validierungsCode: string) {
    // tslint:disable-next-line: max-line-length
    const link =
      'https://downloads.kispro.de/tnbdownload.php?k=' +
      kursCode +
      '&t=' +
      teilnahmeCode +
      '&v=' +
      validierungsCode;

    window.open(link, '_blank');
  }
}
