

<div class="container pb-10 align-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" [@fadeIn]>
    <div class="row mb-3">
      <div class="col m-2">
        <div class="row fw-bold fs-3"> Übersicht</div>
          <div class="row fw-bold"> Kurstitel: </div>
           <div class="row"> {{kurs?.Kursname}}</div>
          <div class="row fw-bold"> Datum:
            </div>
            <div class="row">{{ convertDate(kurs?.Datum)}}</div>
            <div class="row fw-bold">Dauer: </div>
            <div class="row">
              {{calcTime(kurs?.Startzeit, kurs?.Endzeit)}}  Stunden</div>
              <div class="row fw-bold">Gebühr: </div>
                <div class="row">{{kurs?.Preis}} € </div>

              <div class="row fw-bold">Beschreibung:</div>
        <p></p>
        <div class="row fw-bold">Veranstaltungsort:</div>
          <div class="row"> {{kurs?.Ortsbezeichnung}}</div>
            <div class="row">{{kurs?.Strasse}}</div>
              <div class="row">{{kurs?.PLZ}} {{kurs?.Ort}}</div>


      </div>
     
        <div class="col">
           <div class="row fw-bold fs-3">Kurstermin</div>
           <div class="row fw-bold"> Datum: </div>
           <div class="row">{{ convertDate(kurs?.Datum)}} </div>
           <div class="row fw-bold"> Uhrzeit: 
             </div>
             <div class="row">{{convertTime(kurs?.Startzeit)}} Uhr </div>
             <div class="row fw-bold"> Ort: 
            </div>
            <div class="row">{{kurs?.Ort}}</div> 

      </div>  

    </div>
</div>
<div class="pt-4" [@fadeIn]><app-teilnehmerkurse></app-teilnehmerkurse></div>
    


