<div class="row justify-content-center" [@fadeIn]>
  <div class="col-12 col-md-8 col-lg-6 col-xl-5">
    <div class="card shadow-sm p-3" style="border-radius: 1rem">
      <div class="card-body p-3 text-center">
        <div class="row ">
          <div class="col fs-2 fw-bold text-center pb-1">FirmenManager</div></div>
          <div class="row">
            <div class="col fs-4 fw-bold text-center pb-5">
            Login</div>
          </div>
        <form
          [formGroup]="angForm"
          (ngSubmit)="postdata(angForm)"
          autocomplete="on"
        >
        <div class="form-outline mb-3">
          <div class="form-group row">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="form-control shadow-sm" style="border-radius: 5px 0px 0px 5px">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                  </svg>
                </div>
              </div> 
            <input
              type="email"
              class="form-control shadow-sm"
              name="login-username"
              placeholder="E-Mail Adresse"
              formControlName="email"
            />
          </div>
          </div>
        </div>
          <div class="form-outline mb-5">
            <div class="form-group row">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="form-control shadow-sm" style="border-radius: 5px 0px 0px 5px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
                      <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                      <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </div>
                </div> 
            <input
              type="password"
              class="form-control shadow-sm"
              name="login-password"
              placeholder="Passwort"
              formControlName="password"
            />
          </div>
          </div>
          <div class="row text-danger" style="font-size: 12px;" *ngIf="fehlermeldung" >
            <div class="col ">Ihre eingegebenen Daten waren nicht korrekt.</div></div>
          </div>

          <button
            type="submit"
            class="btn btn-outline px-5"
            [disabled]="!angForm.valid"
          >
            Anmelden
          </button>
        </form>
      </div>
      <div class="row text-center">
        <div class="col login-footer">
          <a class="mouse-login" (click)="openImpressum()">Impressum</a> |
          <a class="mouse-login" (click)="openDatenschutz()">Datenschutz</a>
        </div>
      </div>
    </div>
  </div>
</div>

