<div class="row justify-content-center" [@fadeIn]>
    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
      <div class="card shadow p-2" style="border-radius: 1rem">
        <div class="card-body p-3">
          <h2 class="fw-bold mb-0 p-3 text-center">Meine persönlichen Daten</h2>

          <div class="row">
            <div class="col-4">
              <label for="nachname" class="col-form-label fw-bold">Nachname</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="nachname"
                value="{{ user?.Nachname }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="vorname" class="col-form-label fw-bold">Vorname</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="vornamusere"
                value="{{ user?.Vorname }}"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <label for="email" class="col-form-label fw-bold"
                >E-Mail-Adresse</label
              >
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="email"
                value="{{ user?.LoginEmail }}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-6 col-xl-5">
      <div class="card shadow p-2" style="border-radius: 1rem">
        <div class="card-body p-3">
          <h2 class="fw-bold mb-0 p-3 text-center">Firmendaten</h2>

          <div class="row">
            <div class="col-4">
              <label for="nachname" class="col-form-label fw-bold">Firma</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="nachname"
                value="{{ firma?.Firma }}"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <label for="email" class="col-form-label fw-bold"
                >E-Mail-Adresse</label
              >
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="email"
                value="{{ firma?.Email }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="strasse" class="col-form-label fw-bold">Straße</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="strasse"
                value="{{ firma?.Strasse }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="plz" class="col-form-label fw-bold">PLZ</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="ort"
                value="{{ firma?.PLZ }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="ort" class="col-form-label fw-bold">Ort</label>
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="ort"
                value="{{ firma?.Ort }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <label for="festnetz" class="col-form-label fw-bold"
                >Telefon</label
              >
            </div>
            <div class="col">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="festnetz"
                value="{{ firma?.Telefon }}"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
