import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { SharedService } from './shared.service';
import { AppComponent } from './app.component';
import { PersonalComponent } from './personal/personal.component';
import { ZeigePersonalComponent } from './personal/zeige-personal/zeige-personal.component';
import { AddEditPersonalComponent } from './personal/add-edit-personal/add-edit-personal.component';
import { FirmaComponent } from './firma/firma.component';
import { KurseComponent } from './kurse/kurse.component';
import { ZeigeKurseComponent } from './kurse/zeige-kurse/zeige-kurse.component';
import { ZeigeBuchbarekurseComponent } from './kurse/zeige-buchbarekurse/zeige-buchbarekurse.component';
import { ZeigeGebuchtekurseComponent } from './kurse/zeige-gebuchtekurse/zeige-gebuchtekurse.component';
import { LoginComponent } from './login/login.component';
import { ZeigeTeilgenommenekurseComponent } from './kurse/zeige-teilgenommenekurse/zeige-teilgenommenekurse.component';
import { AuditComponent } from './audit/audit.component';
import { AnfrageComponent } from './kurse/anfrage/anfrage.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { HomeComponent } from './home/home.component';
import { TeilnehmerkurseComponent } from './kurse/teilnehmerkurse/teilnehmerkurse.component';
import { SelbstverwaltungComponent } from './selbstverwaltung/selbstverwaltung.component';
import { RegistrationComponent } from './registration/registration.component';
import { KontaktwidgetComponent } from './kontaktwidget/kontaktwidget.component';
import { InterneKurseComponent } from './interne-kurse/interne-kurse.component';
import { VergangeneKurseComponent } from './vergangene-kurse/vergangene-kurse.component';
import {CdkListboxModule} from '@angular/cdk/listbox';





@NgModule({
  declarations: [
    AppComponent,
    PersonalComponent,
    FirmaComponent,
    ZeigePersonalComponent,
    AddEditPersonalComponent,
    KurseComponent,
    ZeigeKurseComponent,
    LoginComponent,
    ZeigeBuchbarekurseComponent,
    ZeigeGebuchtekurseComponent,
    ZeigeTeilgenommenekurseComponent,
    AuditComponent,
    AnfrageComponent,
    KontaktComponent,
    HomeComponent,
    TeilnehmerkurseComponent,
    SelbstverwaltungComponent,
    RegistrationComponent,
    KontaktwidgetComponent,
    InterneKurseComponent,
    VergangeneKurseComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    CdkListboxModule,
  ],
  providers: [SharedService],
  bootstrap: [AppComponent]
})
export class AppModule { }
