import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatDialog} from '@angular/material/dialog';
import { AddEditPersonalComponent } from '../add-edit-personal/add-edit-personal.component';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-zeige-personal',
  templateUrl: './zeige-personal.component.html',
  styleUrls: ['./zeige-personal.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ZeigePersonalComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
    public dialog: MatDialog,
    // tslint:disable-next-line: variable-name
    private snackBar: MatSnackBar
  ) {}

  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  green = 'green-snackbar';
  red = 'warn-snackbar';

  profilForm = new UntypedFormGroup({
    vorname: new UntypedFormControl(''),
    nachname: new UntypedFormControl(''),
    geschlecht: new UntypedFormControl(''),
    geburtsdatum: new UntypedFormControl(''),
    strasse: new UntypedFormControl(''),
    plz: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    telefon: new UntypedFormControl(''),
    mobil: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
  });

  person: any;
  error: any;
  personal: any;
  editActive: any;
  errors!: any[];

  ngOnInit(): void {
    this.loadMitarbeiter();
    this.disabled();
  }

  // tslint:disable-next-line: typedef
  loadMitarbeiter() {
    const personalID = this.route.snapshot.paramMap.get('id');
    this.service.getTeilnehmerById(personalID).subscribe((data2: any) => {
      this.person = data2;
      this.profilForm.controls['vorname'].setValue(data2.Vorname);
      this.profilForm.controls['nachname'].setValue(data2.Nachname);
      this.profilForm.controls['geschlecht'].setValue(data2.Geschlecht);
      this.profilForm.controls['geburtsdatum'].setValue(this.convertDate(data2.GebDat));
      this.profilForm.controls['strasse'].setValue(data2.Strasse);
      this.profilForm.controls['plz'].setValue(data2.PLZ);
      this.profilForm.controls['ort'].setValue(data2.Ort);
      this.profilForm.controls['telefon'].setValue(data2.TelFestnetz);
      this.profilForm.controls['mobil'].setValue(data2.TelMobil);
      this.profilForm.controls['email'].setValue(data2.Email);
    });
  }

  // tslint:disable-next-line: typedef
  edit() {
    this.editActive = true;
    this.profilForm.controls['geschlecht'].enable();
    this.profilForm.controls['strasse'].enable();
    this.profilForm.controls['plz'].enable();
    this.profilForm.controls['ort'].enable();
    this.profilForm.controls['telefon'].enable();
    this.profilForm.controls['mobil'].enable();
    this.profilForm.controls['email'].enable();
  }

  // tslint:disable-next-line: typedef
  disabled() {
    this.editActive = false;
    this.profilForm.controls['vorname'].disable();
    this.profilForm.controls['nachname'].disable();
    this.profilForm.controls['geburtsdatum'].disable();
    this.profilForm.controls['geschlecht'].disable();
    this.profilForm.controls['strasse'].disable();
    this.profilForm.controls['plz'].disable();
    this.profilForm.controls['ort'].disable();
    this.profilForm.controls['telefon'].disable();
    this.profilForm.controls['mobil'].disable();
    this.profilForm.controls['email'].disable();
  }

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date === null) {
      return '';
    } else {
      const [year, month, days] = date.split('-');
      // tslint:disable-next-line: no-unused-expression
      return days + '.' + month + '.' + year;
    }
  }

  // tslint:disable-next-line: typedef
  updatePersonal(form: UntypedFormGroup) {
    const tnid = this.route.snapshot.paramMap.get('id');
    this.service.getUser().subscribe((data: any) => {
      const val = {
        TnID: tnid,
        Name: this.person.Nachname,
        Vorname: this.person.Vorname,
        GebDat: this.person.GebDat,
        FirmenID: data.FirmenID,
        Strasse: form.value.strasse,
        Ort: form.value.ort,
        PLZ: form.value.plz,
        TelFestnetz : form.value.telefon,
        TelMobil: form.value.mobil,
        Email: form.value.email,
        Geschlecht: form.value.geschlecht,
      };
      this.service.updateTeilnehmer(val).subscribe((data2: any) => {
        if (data2.status === 'updateError') {
          this.openSnackBar(data2.message, this.green, 'OK');
        }
        this.ngOnInit();
        if (data2.status === 'success') {
          this.openSnackBar(data2.message, this.green, 'OK');
        }
        this.ngOnInit();
      });
    });
  }

  // tslint:disable-next-line: typedef
  openDialog(person: any) {
    const dialogRef = this.dialog.open(AddEditPersonalComponent, {
      data: { Personal: person },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadMitarbeiter();
    });
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, panel: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [panel],
    });
  }
}
