<div class="container align-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" [@fadeIn]>

  <div class="row fs-4 fw-bold">
    <div class="col">  Übersicht</div>
    <div class="col text-end">
      <button type="button" class="btn btn-outline-secondary btn-sm p-2" 
      (click)="openDialog()">
        Mitarbeitende hinzufügen
      </button>
      </div> 
      </div>
<div class="row">
  <div class="col"> Hier haben Sie eine Übersicht über alle Ihrer Mitarbeitenden. Zudem sehen Sie welche Ihrer Mitarbeitenden bereits einen Erste Hilfe Kurs absolviert haben und welche bereits abgelaufen sind.</div>
</div>

<div class="columns pt-3 ">
    <input [(ngModel)]="PersonalFilter" class="form-control"
    (keyup)="FilterFn()" placeholder="Suchen">
  </div>
  <div class="table-responsive">
<table class="table table-hover">
  <thead>
      <tr>
        <th scope="col">
          <div>
                      Nachname
                      <a (click)="sortResult('Nachname',true)" style="cursor: pointer;">↑
                      </a><!-- eslint-disable --><a (click)="sortResult('Nachname',false)" style="cursor: pointer;"> ↓</a>
                   </div>
        
        </th>
            <th scope="col">
              <div>
              Vorname
                <a (click)="sortResult('Vorname',true)" style="cursor: pointer;">↑
                </a><!-- eslint-disable --><a (click)="sortResult('Vorname',false)" style="cursor: pointer;">↓</a>
            </div>
</th> 


<th scope="col">
  <div>
    Ersthelfende bis
              <a (click)="sortResultDatum('Expired',true, 'CheckedOut')" style="cursor: pointer;">↑
              </a><a (click)="sortResultDatum('Expired',false,  'CheckedOut')" style="cursor: pointer;"> ↓</a>
           </div>

</th>

<th scope="col"  class="text-center">Ansicht</th>
<th scope="col"  class="text-center">Option</th>

        </tr>
    </thead>
    <tbody>
      <tr class="clickable" *ngFor="let dataItem of PersonalList" [@fadeIn]>
        <td>{{dataItem.Nachname}}</td>
        <td>{{dataItem.Vorname}} </td>
        <td *ngIf="dataItem.CheckedOut !== null && dataItem.WarAnwesend === '1' && dataItem.Expired !== null"> {{convertDate(dataItem.Expired)}} </td>
        <td *ngIf="dataItem.CheckedOut === null || dataItem.WarAnwesend !== '1' || dataItem.Expired === null"> - </td>
           <td class="text-center">
             <button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/personal/mitarbeitende/', dataItem.TnID]">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
             </button>
</td>
            <td class="text-center"><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/buchbar']">
            Kurs buchen
            </button>
          </td>

       </tr>
    </tbody>
</table>
</div>
</div>

