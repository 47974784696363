<nav
  class="navbar navbar-expand-lg navbar-light shadow p-3 radient-custom"
  style="background-color: {{ gliederung?.OL_Navbar_BGColor }};"
  *ngIf="loggedIn"
  [@fadeIn]
>
  <img
    src="{{ gliederung?.OL_PicURL_Logo }}"
    alt=""
    class="mx-3"
    routerLink="home"
    style="max-width: 300px; max-height: 100px;" 
  />
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="home"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
          >Home
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="audit"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
          >Ersthelfende</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="personal/mitarbeitende"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
          >Mitarbeitende</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="kurse/buchbar"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
          >Kursangebot</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="kurse/gebucht"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
          >Ihre Buchungen</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="intern/kurse">Interne Kurse</a>
      </li> -->

      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="anfrage">Kursanfrage</a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="kontakt">Kontakt</a>
      </li> -->
    </ul>
    <ul class="navbar-nav ms-auto">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="firma"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
        >
          <div
            class="row fw-bold"
            style="padding-bottom: 0px; margin-bottom: 0px"
          >
            {{ user?.Vorname }} {{ user?.Nachname }}
          </div>
          <div
            class="row"
            style="font-size: 12px; padding-top: 0px; margin-top: 0px"
          >
            {{ firma?.Firma }}
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link fs-4"
          (click)="logout()"
          routerLink="login"
          style="color: {{ gliederung?.OL_Navbar_FontColor }}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-box-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
            />
            <path
              fill-rule="evenodd"
              d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
            /></svg
        ></a>
      </li>
    </ul>
  </div>
</nav>

<div class="container p-2 my-5 pt-4 justify-content-center">
  <div class="row">
    <div class="col">
      <router-outlet> </router-outlet>
    </div>

    <div
      class="col-1 m-3 flex justify-content-center align-items-center"
      *ngIf="loggedIn"
    >
      <app-kontaktwidget></app-kontaktwidget>
    </div>
  </div>
</div>

<!-- <p>{{ ipAddress }} {{ userAgent }} </p> -->

<footer
  class="fixed-bottom footer mt-2"
  *ngIf="loggedIn"
  [@fadeIn]
  style="background-color: {{ gliederung?.OL_Footer_BGColor }};"
>
  <hr style="padding: 0px; margin: 0px" />
  <div
    class="row p-2 footer-text mx-2"
    style="color: {{ gliederung?.OL_Footer_FontColor }}; "
  >
    <div class="col">
      &copy; Copyright 2023
      <a
        class="mouse"
        (click)="openKIS()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
      >
        <u>KISpro</u></a
      >
    </div>
    <div class="col text-end">
      <a
        class="mouse"
        (click)="openImpressum()"
        style="color: {{ gliederung?.OL_Footer_FontColor }}; "
        >Impressum</a
      >
      |
      <a
        class="mouse"
        (click)="openDatenschutz()"
        style="color: {{ gliederung?.OL_Footer_FontColor }};"
        >Datenschutz</a
      >
    </div>
  </div>
</footer>
