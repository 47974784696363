

import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-kurse',
  templateUrl: './kurse.component.html',
  styleUrls: ['./kurse.component.css']
})
export class KurseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
    ) { }

  KursList: any = [];
  KursListWithoutFilter: any = [];
  KurseFilter = '';

  ngOnInit(): void {
    this.refreshKursList();
  }

  // tslint:disable-next-line: typedef
  refreshKursList(){
    const personalID = this.route.snapshot.paramMap.get('id');
    this.service.getZukuenftigeKurse(personalID).subscribe((data: any) => {
      this.KursList = data;
    });
    
  }


  // tslint:disable-next-line: typedef
  FilterFn(){
    const KurseFilter = this.KurseFilter;
    const KursdatumFilter = this.KurseFilter;

    this.KursList = this.KursListWithoutFilter.filter((el: any) => {
        return el.Kurstitel.toString().toLowerCase().includes(
          KurseFilter.toString().trim().toLowerCase()) ||
          el.Annahmedatum.toString().toLowerCase().includes(
            KursdatumFilter.toString().trim().toLowerCase()
          );
    });
  }


// tslint:disable-next-line: typedef
sortResult(prop: any, asc: any){
    // tslint:disable-next-line: typedef
    this.KursList = this.KursListWithoutFilter.sort((a: any, b: any) => {
      if (asc){
        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      }else{
        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    // tslint:disable-next-line: no-unused-expression
    let year = '';
    let month = '';
    let days = '';

    for (let i = 0; i <= date.length; i++){
      if (i < 4){
        year += date[i];
      }
      if (i > 4 && i < 7){
        month += date[i];
      }
      if (i > 7 && i < date.length ){
        days += date[i];
      }
    }
    return days + '.' + month + '.'  +  year;
  }

}



