import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZeigeKurseComponent } from '../zeige-kurse/zeige-kurse.component';

@Component({
  selector: 'app-teilnehmerkurse',
  templateUrl: './teilnehmerkurse.component.html',
  styleUrls: ['./teilnehmerkurse.component.css'],
})
export class TeilnehmerkurseComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
    public dialog: MatDialog
  ) {}

  PersonalList: any = [];
  PersonalListWithoutFilter: any = [];
  PersonalFilter = '';
  teilnehmende: any;

  erlaubteAnzahlTeilnehmer: any;
  kursDetail: any;

  ngOnInit(): void {
    this.refreshPersonalList();
  }

  // tslint:disable-next-line: typedef
  refreshPersonalList() {
    const kursID = this.route.snapshot.paramMap.get('id');
    this.service.getKursDetailById(kursID).subscribe((data: any) => {
      this.kursDetail = data;
      this.service.getAnzahlTeilnahmenKurs(kursID).subscribe((data2: any) => {
        this.erlaubteAnzahlTeilnehmer = Number(data.MaxTNZahl) - data2.Anzahl;
      });
      this.service.getTeilnehmendeKurs(kursID).subscribe((data: any) => {
        this.teilnehmende = data;
      });
      // if (this.kursDetail.Kurzel === 50){ // Folgekurs
      // this.service.getKursKeinTeilnehmer(kursID).subscribe(data => {
      //   const teilnehmer: any = [];
      //   // tslint:disable-next-line: prefer-for-of
      //   for ( let i = 0; i < data[1].length; i++) {
      //     console.log(data[1][i]);
      //     if (this.kursDetail.Kurzel === data[1][i].Folgekurs){
      //         teilnehmer.push(data[1][i]);
      // }
      // }

      this.service
        .getPersonalListZumKursAngemeldet(kursID)
        .subscribe((listPersonal: any) => {
          this.PersonalList = listPersonal;
          this.PersonalListWithoutFilter = listPersonal;
        });
      // this.PersonalList = teilnehmer;
      // this.PersonalListWithoutFilter = teilnehmer;
      // });
      // }
      // else{
      //   this.service.getKursKeinTeilnehmer(kursID).subscribe(data => {
      //     this.PersonalList = data[0];
      //     this.PersonalListWithoutFilter = data[0];
      //   });
      // }
    });

  }

  // tslint:disable-next-line: typedef
  FilterFn() {
    const PersonalNachnameFilter = this.PersonalFilter;
    const PersonalVornameFilter = this.PersonalFilter;
    //const PersonalErsthelferFilter = this.PersonalFilter;

    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.filter((el: any) => {
      return (
        el.Nachname.toString()
          .toLowerCase()
          .includes(PersonalNachnameFilter.toString().trim().toLowerCase()) ||
        el.Vorname.toString()
          .toLowerCase()
          .includes(PersonalVornameFilter.toString().trim().toLowerCase()) // ||
        // el.Ersthelfer.toString()
        //   .toLowerCase()
        //   .includes(PersonalErsthelferFilter.toString().trim().toLowerCase())
      );
    });
  }

  // tslint:disable-next-line: typedef
  addTeilnahmen(teilnehmer: any) {
    this.service
      .addBuchung(this.kursDetail.KursID, teilnehmer)
      .subscribe((res) => {
      });
    this.refreshPersonalList();
  }

  // tslint:disable-next-line: typedef
  sortResult(prop: any, asc: any) {
    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.sort(
      (a: any, b: any) => {
        if (asc) {
          return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
        } else {
          return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
        }
      }
    );
  }

  // tslint:disable-next-line: typedef
  sortResultDatum(prop: any, asc: any) {
    // tslint:disable-next-line: typedef
    this.PersonalList = this.PersonalListWithoutFilter.sort(
      (a: any, b: any) => {
        if (asc) {
          // tslint:disable-next-line: max-line-length
          return this.convertDate(a[prop]) > this.convertDate(b[prop])
            ? 1
            : this.convertDate(a[prop]) < this.convertDate(b[prop])
            ? -1
            : 0;
        } else {
          // tslint:disable-next-line: max-line-length
          return this.convertDate(b[prop]) > this.convertDate(a[prop])
            ? 1
            : this.convertDate(b[prop]) < this.convertDate(a[prop])
            ? -1
            : 0;
        }
      }
    );
  }

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date === 'kein Kurs') {
      return '0000.00.00';
    } else {
      // tslint:disable-next-line: no-unused-expression
      let year = '';
      let month = '';
      let day = '';

      for (let i = 0; i <= date.length; i++) {
        if (i < 2) {
          day += date[i];
        }
        if (i > 2 && i < 5) {
          month += date[i];
        }
        if (i > 5 && i < date.length) {
          year += date[i];
        }
      }
      return year + '.' + month + '.' + day;
    }
  }

    // tslint:disable-next-line: typedef
    convertBirthday(date: any) {
      if (date != null) {
        const [year, month, days] = date.split('-');
        return days + '.' + month + '.' + year;
      } else {
        return;
      }
    }

  openDialog(teilnehmer: any, kurs: any): void {
    const dialogRef = this.dialog.open(DialogAnmeldenDialog, {
      data: { Teilnehmer: teilnehmer, Kurs: kurs },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshPersonalList();
    });
  }


  openDialog2(teilnehmerid: any, kursid: any): void {
    const dialogRef = this.dialog.open(DialogAbmeldenDialog, {
      data: { tnID: teilnehmerid, kursID: kursid },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-anmelden-dialog',
  templateUrl: 'dialog-anmelden.html',
})
// tslint:disable-next-line: component-class-suffix
export class DialogAnmeldenDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogAnmeldenDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar
  ) {}
  Teilnahmen: any = [];
  teilnahmeID = 0;

  // tslint:disable-next-line: typedef
  anmelden(teilnehmer: any, kurs: any) {
    const val = {
      KursID: kurs,
      tnID: teilnehmer,
    };
    this.service.addBuchung(kurs, teilnehmer).subscribe((res: any) => {
      console.log(res);
    });
    this.dialogRef.close();
    this.openSnackBar(
      'Die Anmeldung zum Erste Hilfe Kurs war erfolgreich',
      false
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar(
      'Die Anmeldung zum Erste Hilfe Kurs wurde abgebrochen',
      true
    );
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, warn: boolean) {

    if (warn) {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['warn-snackbar'],
      });
    } else {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['green-snackbar'],
      });
    }
  }
}


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-abmelden-dialog',
  templateUrl: 'dialog-abmelden.html',
})
// tslint:disable-next-line: component-class-suffix
export class DialogAbmeldenDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogAbmeldenDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    // tslint:disable-next-line: variable-name
    private _snackBar: MatSnackBar
  ) {}
  Teilnahmen: any = [];
  teilnahmeID = 0;
  // tslint:disable-next-line: typedef
  delete(kursID: any, teilnID: any) {
    this.service.removeBuchung(kursID, teilnID).subscribe((data: any) => {});
    this.dialogRef.close();
    this.openSnackBar('Die Buchung wurde storniert', false);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.openSnackBar('Die Stornierung wurde abgebrochen', true);
  }

  // tslint:disable-next-line: typedef
  openSnackBar(message: string, warn: boolean) {
    if (warn) {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['warn-snackbar'],
      });
    } else {
      this._snackBar.open(message, '', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5 * 1000,
        panelClass: ['green-snackbar'],
      });
    }
  }
}
