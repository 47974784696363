import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import urls from 'src/assets/urls.json';

const headers = { 'content-type': 'application/json' };
@Injectable({
  providedIn: 'root',
})
export class SelbstverwaltungService {
  constructor(private http: HttpClient) {}

  // tslint:disable-next-line: typedef
  getKurse(kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'sv',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

  // tslint:disable-next-line: typedef
  getAnzahlTeilnahmen(kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'anzahl',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

  // tslint:disable-next-line: typedef
  addTeilnahmen(val: any, kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'anmelden',
      data: val,
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

  // tslint:disable-next-line: typedef
  getTeilnahme(kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'teilnahmen',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }
  // tslint:disable-next-line: typedef
  getFirma(kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'firma',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

  // tslint:disable-next-line: typedef
  deleteTeilnahme(val: any, kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'deleteTeilnahme',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
      data: val,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

  // tslint:disable-next-line: typedef
  updateTeilnehmer(val: any, kurs: any, kursKey: any, companyKey: any) {
    const body = {
      action: 'updateTeilnehmer',
      kursCode: kurs,
      key: kursKey,
      companykey: companyKey,
      data: val,
    };
    return this.http.post(urls.APIUrlselbst, body, { headers });
  }

    // tslint:disable-next-line: typedef
    getGliederung(kurs: any, kursKey: any) {
      const body = {
        action: 'getGliederung',
        kursCode: kurs,
        key: kursKey
      };
      // tslint:disable-next-line: object-literal-shorthand
      return this.http.post(urls.APIUrlselbst, body, {headers});
    }
}
