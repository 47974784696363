
<div class="container align-items-center justify-content-center shadow p-3" style="border-radius: 1rem; max-width: 500px;">
          <h2 class="fw-bold mb-0 p-3 text-center">Kursanfrage</h2>
          <p>Hier können Sie eine Anfrage an einen 1. Hilfe-Kurs für Ihr Unternehmen stellen. Geben Sie dafür die erforderlichen Informationen im Formular ein.</p>
            <div class="row py-1"><label for="kursort" class="form-label fw-bold">Kursort:</label>  </div>
              <div class="row"><input type="text"  class="form-control form-control-sm" id="kursort" placeholder="Kursort"> </div>
              <div class="row pt-2" > <label for="anzahl" class="form-label fw-bold">Anzahl:</label></div>
              <div class="row"><input type="number" class="form-control form-control-sm" id="anzahl" placeholder="Anzahl der Teilnehmenden"> </div>
              <div class="row pt-2" ><label for="wunschdatum1" class="form-label fw-bold">1. Wunschdatum: </label></div>
                <div class="row p-1"> <input type="date" class="form-control form-control-sm" id="wunschdatum1" placeholder="Wunschdatum"> </div>
                <div class="row pt-2"><label for="wunschdatum2" class="form-label fw-bold">2. Wunschdatum:</label>  </div>
                <div class="row p-1"> <input type="date" class="form-control form-control-sm" id="wunschdatum2" placeholder="Wunschdatum"></div> 
                <div class="row pt-2"> <label for="wunschdatum3" class="form-label fw-bold">3. Wunschdatum: </label> </div>
                <div class="row p-1"> <input type="date" class="form-control form-control-sm" id="wunschdatum3" placeholder="Wunschdatum"></div>
              <div class="row p-1"> <label for="wunschtag" class="form-label fw-bold">Wunschtage:</label>  </div>
              <div class="row p-1">
                <div class="col">  <input class="form-check-input"  type="checkbox"   id="wunschtag1" >   <label class="form-check-label px-1" for="wunschtag1">Mo </label></div>
                <div class="col">   <input class="form-check-input" type="checkbox"   id="wunschtag2">  <label class="form-check-label px-1" for="wunschtag2">Di</label> </div>
                <div class="col">  <input class="form-check-input" type="checkbox"   id="wunschtagg3" >  <label class="form-check-label px-1" for="wunschtag3">Mi</label> </div>
                <div class="col">  <input class="form-check-input" type="checkbox"   id="wunschtag4" >  <label class="form-check-label px-1" for="wunschtag4">Do</label> </div>
              </div>
                <div class="row p-1">
                <div class="col">  <input class="form-check-input"  type="checkbox"   id="wunschtag5" >   <label class="form-check-label px-1" for="wunschtag5"> Fr </label></div>
                <div class="col">   <input class="form-check-input" type="checkbox"   id="wunschtag6">  <label class="form-check-label px-1" for="wunschtag6">Sa</label> </div>
                <div class="col">  <input class="form-check-input" type="checkbox"   id="wunschtag7" >  <label class="form-check-label px-1" for="wunschtag7">So</label> </div>
                <div class="col"> </div>
              </div> 
              <div class="row pt-2"><label for="raumgroesse" class="form-label fw-bold">Raumgröße:</label>  </div>
                <div class="row"><input type="number" class="form-control form-control-sm" id="raumgroesse" placeholder="Raumgröße in qm">  </div>
                <div class="row pt-2"> <label for="raumgroesse" class="form-label fw-bold">Vorhandene Ausstattung: </label>  </div>
               <div class="row">
                  <div class="col">  <input class="form-check-input"  type="checkbox"   id="vorhandeneAusstattung1" >   <label class="form-check-label px-1" for="vorhandeneAusstattung1">Overhead </label></div>
                  <div class="col">   <input class="form-check-input" type="checkbox"   id="vorhandeneAusstattung2">  <label class="form-check-label px-1" for="vorhandeneAusstattung2">Beamer</label> </div>
                </div>
                <div class="row">
                  <div class="col">  <input class="form-check-input" type="checkbox"   id="vorhandeneAusstattung3" >  <label class="form-check-label px-1" for="vorhandeneAusstattung3">Leinwand</label> </div>
                  <div class="col">  <input class="form-check-input" type="checkbox"   id="vorhandeneAusstattung4" >  <label class="form-check-label px-1" for="vorhandeneAusstattung4">Musikanlage</label> </div>
                </div> 
                <div class="row p-3">
                  <div class="col"></div>
                  <div class="col text-end"> <button class="btn btn-outline-secondary btn-sm" type="submit">Anfrage versenden</button></div>
              </div>

              </div>
         