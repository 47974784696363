<!-- Button trigger modal -->

  

<div class="container align-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" [@fadeIn]>
    <div class="row fs-4 fw-bold">
        <div class="col"> Ihre Buchungen </div>
    </div>
    <div class="row">
        <div class="col pb-4"> Hier haben Sie eine Übersicht über die bereits getätigten Buchungen für Ihre Mitarbeitenden. Ebenso können Sie Ihren Mitarbeitenden wieder vom Erste Hilfe Kurs abmelden.</div>
    </div>
<div class="table-responsive" *ngIf="anzahlDerKursteilnehmer > 0">
    <table class="table is-hoverable">
        <thead>
            <tr>
                <th class="text-center">Kursansicht</th>
    <th> Name</th>
    <th> Vorname</th>
    <th> Datum </th>
    <th> Uhrzeit </th>
    <th> Kurstitel</th>
        <th>Ort</th>
<th class="text-center">  Mitarbeitende abmelden </th>
        </thead>
        <tbody>
            <tr class="clickable" *ngFor="let dataItem of KursList; index as i" >
                <td class="text-center"><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/', dataItem.KursID]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                      </svg>
                    </button></td>
                <td>{{dataItem.Nachname}}</td>
                <td>{{dataItem.Vorname}}</td>
                <td [routerLink]="['/kurse/', dataItem.KursID]">{{convertDate(dataItem.Datum)}}</td>
                <td [routerLink]="['/kurse/', dataItem.KursID]">{{convertTime(dataItem.Startzeit)}}-{{convertTime(dataItem.Endzeit)}}</td>
                <td [routerLink]="['/kurse/', dataItem.KursID]">{{dataItem.Kursname}}</td>
                <td>{{dataItem.Ort}}</td>

                <td class="text-center">                
                    <button type="button" class="btn btn-outline-secondary btn-sm"
                  (click)="openDialog(dataItem.TnID, dataItem.KursID)" >  
                  vom Kurs abmelden
                </button>
            </td>
            
            </tr>

        </tbody>
    </table>

    </div>
    <div class="row p-3" *ngIf="anzahlDerKursteilnehmer == 0">
        <div class="col fw-bold" >
            Aktuell sind keine Kurse für Ihre Mitarbeitende gebucht
        </div>  
        </div>
<div class="row p-3" *ngIf="anzahlDerKursteilnehmer == 0">         <div class="col fw-bold" >
    Buchen Sie <a [routerLink]="['/kurse/buchbar']" style="color: black">hier</a> einen Erste Hilfe Kurs für Ihre Mitarbeitende
</div> </div>
    </div>
