import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-interne-kurse',
  templateUrl: './interne-kurse.component.html',
  styleUrls: ['./interne-kurse.component.css']
})
export class InterneKurseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private service: SharedService
  ) { }


  interneKursListe: any;
  interneKursListeWithoutFilter: any;
  ngOnInit(): void {
    this.getInterneKurse();
  }


// tslint:disable-next-line: typedef
getInterneKurse(){
  this.service.getFirmaInterneKurse().subscribe((data: any) => {
    console.log(data);
    this.interneKursListe = data;
    this.interneKursListeWithoutFilter = data;
  });
}

  // tslint:disable-next-line: typedef
  convertDate(date: any) {
    if (date != null) {
      const [year, month, days] = date.split('-');
      return days + '.' + month + '.' + year;
    } else {
      return;
    }
  }

  // tslint:disable-next-line: typedef
  convertTime(time: string) {
    return time.substring(0, time.length - 3);
  }
  // tslint:disable-next-line: typedef
  sortResult(prop: any, asc: any) {
    // tslint:disable-next-line: typedef
    this.interneKursListe = this.interneKursListeWithoutFilter.sort((a: any, b: any) => {
      if (asc) {
        return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
      } else {
        return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
      }
    });
  }
}
