<div class="container align-items-center justify-content-center p-3 shadow p-3" style="border-radius: 1rem;" [@fadeIn]>

  <div class="row">
    <div class="col p-3">
      <div class="row ">
        <div class="col fw-bold fs-5"><u>Ihre Ersthelfendeübersicht</u></div>
      </div>
      <div class="row">
        <div class="col fw-bold">{{ firma?.Mitarbeiter }}</div>
        <div class="col fw-bold">Mitarbeitende</div>
        </div>
        <div class="row">
          <div class="col fw-bold">{{ firma?.BedarfErsthelfende }}</div>
          <div class="col fw-bold">benötigte Ersthelfende</div>
          </div>
          <div class="row">
            <div class="col fw-bold">
              {{ AnzahlErsthelfer }}
            </div>
            <div class="col fw-bold">Ersthelfende</div>
          </div>
    </div>
    <div class="col p-3">
   <div class="row"><div class="col fs-5 fw-bold"><u>Die nächsten Fristabläufe</u></div></div> 
   <div class="table-responsive">
    <table class="table is-hoverable" style="  display: block; height: 300px; overflow-y: scroll;">
        <thead>
            <tr>
    
    <th scope="col">
    <div>
                Nachname
             </div>
    
    </th>
    <th scope="col">
      <div>
      Vorname
    </div>
    </th> 
    <th> Ersthelfende bis
    </th>
    
                <th class="text-center">Ansicht</th>
    
            </tr>
        </thead>
        <tbody>
          <tr class="clickable" *ngFor="let dataItem of PersonalList1"  >
            <td >{{dataItem.Nachname}} </td>
            <td>{{dataItem.Vorname}} </td>
               <td>{{convertDate(dataItem.Expired)}}</td> 
               <td class="text-center">
                <button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/personal/mitarbeitende/', dataItem.TnID]">
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
               <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
             </svg>
                </button>
    </td>
           </tr>
        </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>

  <hr>
  <div class="row">
    <div class="col p-3" >
      <h2 style="text-align: center">Ersthelfendequote</h2>
      <canvas id="myChart"></canvas>
    </div>
    <div class="col p-3">
      <h2 style="text-align: center">Ersthelfende im Unternehmen</h2>
      <canvas id="myChart2"></canvas>
    </div>
  </div>
