import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-kontaktwidget',
  templateUrl: './kontaktwidget.component.html',
  styleUrls: ['./kontaktwidget.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('700ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeInButton', [
      transition(':enter', [
        style({ opacity: 1 }),
        animate('700s ease-out', style({ opacity: 0})),
      ]),
    ]),
  ],
})
export class KontaktwidgetComponent implements OnInit {
  constructor(private service: SharedService) {}

  gliederung: any;
  showContactForm = true;

  ngOnInit(): void {
    this.loadGliederung();
    // setTimeout(() => {
    //   this.showContactForm = false;
    // }, 4000);
  }

  // tslint:disable-next-line: typedef
  showForm() {
    this.showContactForm = this.showContactForm === true ? false : true;


  }

  // tslint:disable-next-line: typedef
  loadGliederung() {
    this.service.getGliederung().subscribe((data: any) => {
      this.gliederung = data;
    });
  }
}
