<!-- Button trigger modal -->

<div
  class="container align-items-center justify-content-center p-3 shadow"
  style="border-radius: 1rem"
>
  <br />
  <div class="anzeige shadow-sm p-2">
    <div class="row text-center fs-4 text-success" *ngIf="erlaubteAnzahlTeilnehmer > 2">
      <div class="col">
        Anzahl der freien Plätze: <b>{{ erlaubteAnzahlTeilnehmer }}</b>
      </div>
    </div>
    <div
      class="row text-center h3 text-warning"
      *ngIf="erlaubteAnzahlTeilnehmer > 0 && erlaubteAnzahlTeilnehmer <= 2"
    >
    <div class="col">
      Anzahl der freien Plätze: <b>{{ erlaubteAnzahlTeilnehmer }}</b> 
      </div>
    </div>
    <div class="row text-center h3 text-danger" *ngIf="erlaubteAnzahlTeilnehmer <= 0">
     
     <div class="col"> Es sind leider keine weiteren Plätze verfügbar</div>
    </div>
  </div>
  <br />
  <div class="row  fw-bold pt-1">
    <div class="col">Bereits angemeldete Mitarbeitende:</div></div>
    <div class="row" *ngIf="teilnehmende === null"><div class="col"> -</div></div>
            <div class="row flex justify-content-center align-items-center" *ngFor="let dataItem of teilnehmende; index as i"> <div class="col  d-flex align-items-center"><span class="pe-2"> {{dataItem?.Nachname}}, {{dataItem?.Vorname}}, {{convertBirthday(dataItem?.GebDat)}}</span><div class="box" (click)="openDialog2(dataItem?.TnID, dataItem?.KursID)"></div></div></div>

  <div class="d-flex flex-row pt-2" *ngIf="erlaubteAnzahlTeilnehmer > 0">
    <input
      [(ngModel)]="PersonalFilter"
      class="form-control"
      (keyup)="FilterFn()"
      placeholder="Filter"
    />
  </div>
  <div class="table-responsive" *ngIf="erlaubteAnzahlTeilnehmer > 0">
    <table class="table table-responsive table-hover">
      <thead>
        <tr>

          <th scope="col">
            <div>
              Nachname
              <a (click)="sortResult('Nachname', true)">↑ </a
              ><!-- eslint-disable --><a
                (click)="sortResult('Nachname', false)"
              >
                ↓</a
              >
            </div>
          </th>
          <th scope="col">
            <div>
              Vorname
              <a (click)="sortResult('Vorname', true)">↑ </a
              ><!-- eslint-disable --><a (click)="sortResult('Vorname', false)">
                ↓</a
              >
            </div>
          </th>
          <th>zum Kurs anmelden</th>
        </tr>
      </thead>
      <tbody>
        <tr class="clickable" *ngFor="let dataItem of PersonalList">
          <td [routerLink]="['/personal/mitarbeitende/', dataItem.TnID]">
            {{ dataItem.Nachname }}
          </td>
          <td [routerLink]="['/personal/mitarbeitende/', dataItem.TnID]">
            {{ dataItem.Vorname }}
          </td>

          <!-- <td> {{dataItem.Ersthelfer}}  </td> -->
          <td>
            <button type="button" class="btn btn-outline-secondary btn-sm"
              (click)="openDialog(dataItem, kursDetail)"
            >
              zum Kurs anmelden
            </button>
          </td>
          <td
            *ngIf="kursDetail?.MaxTeilnehmer - kursDetail?.Teilnehmerzahl == 0"
          >
            maximale Teilnehmerzahl erreicht
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/buchbar']">
    Zurück
  </button>
</div>
