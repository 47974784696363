import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css'],
})
export class KontaktComponent implements OnInit {
  firma: any;
  error: any;
  gliederung: any;
  constructor(
    private route: ActivatedRoute,
    private service: SharedService,
    private router: Router,

  ) {}

  ngOnInit(): void {
    this.loadFirma();
    this.loadGliederung();
  }

  // tslint:disable-next-line: typedef
  loadFirma() {
    const firmenID = this.route.snapshot.paramMap.get('id');
    // this.service. getFirmaById(firmenID).subscribe(data => this.firma = data,
    //   error => this.error = error);
  }

  // tslint:disable-next-line: typedef
  loadGliederung(){
    this.service.getGliederung().subscribe((data: any) => {
      console.log(data);
      this.gliederung = data;
    });
  }
  // tslint:disable-next-line: typedef
  kontaktAnfrage() {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const element = (<HTMLInputElement> document.getElementById('nachricht'))
      .value;
    const val = {
      Nachname: this.firma.Nachname,
      Vorname: this.firma.Vorname,
      Firma: this.firma.Firma,
      Email: this.firma.Email,
      Frage: element,
    };
    // this.service.addKontaktanfrage(val).subscribe(res =>  res.toString(),
    // err => this.error = err);
  }
  // tslint:disable-next-line: typedef
  get authentification() {
    return localStorage.getItem('Token');
  }
}
