import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ChartOptions } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  PersonalList: any = [];
  firma: any = [];
  currentUser = '';
  AnzahlBenoetigteErsthelfer: any;
  AnzahlErsthelfer: any;
  userId = 0;
  errors = [];
  PersonalList1: any = [];
  personal: any;
  PersonalFilter = '';
  PersonalListWithoutFilter: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: SharedService,

  ) {}
  // tslint:disable-next-line: typedef
  ngOnInit() {
    // tslint:disable-next-line: no-unused-expression
    
  }

  // tslint:disable-next-line: typedef
  ngAfterViewInit() {
    this.getFirma();
    this.refreshPersonalList();
  }

    // tslint:disable-next-line: typedef
    convertDate(date: string) {
      if (date !== '-' && date !== undefined) {
        const [year, month, days] = date.split('-');
        return days + '.' + month + '.' + year;
      } else {
        return date;
      }
    }

    // tslint:disable-next-line: typedef
    refreshPersonalList() {
      this.service
        .getPersonalListFirmenGueltigEHFrist()
        .subscribe((listPersonal: any) => {
          this.PersonalList1 = listPersonal;
          this.PersonalListWithoutFilter = listPersonal;
        });
    }

  // tslint:disable-next-line: typedef
  getFirma() {
    this.service.getFirma().subscribe((firma: any) => {
      this.service.getPersonalListFirmenGueltigEH().subscribe((data3: any) => {
        let AnzahlErsthelfer = 0;
        if (data3 === null) {
          this.AnzahlErsthelfer = 0;
        } else {
          this.AnzahlErsthelfer = data3.length;
          AnzahlErsthelfer = data3.length;
        }
        this.firma = firma;
        const value = firma.BedarfErsthelfende - AnzahlErsthelfer;

        if (value > 0) {
          this.createChart(
            firma.BedarfErsthelfende - AnzahlErsthelfer,
            AnzahlErsthelfer,
            'Quote nicht erfüllt',
            'myChart',
            'Fehlende Ersthelfende',
            'Anzahl Ersthelfende'
          );
        } else if (value < 0) {
          this.createChart(
            0,
            AnzahlErsthelfer,
            'Quote erfüllt',
            'myChart',
            'Fehlende Ersthelfende',
            'Anzahl Ersthelfende'
          );
        } else {
          this.createChart(
            firma.AnzahlBenoetigteErsthelfer - AnzahlErsthelfer,
            AnzahlErsthelfer,
            'Quote erfüllt',
            'myChart',
            'Fehlende Ersthelfende',
            'Anzahl Ersthelfende'
          );
        }
        this.createChart(
          firma.Mitarbeiter - this.AnzahlErsthelfer,
          AnzahlErsthelfer,
          Math.round(((AnzahlErsthelfer * 100) / firma.Mitarbeiter) * 100) /
            100 +
            '%',
          'myChart2',
          'Anzahl Mitarbeitende',
          'Anzahl Ersthelfende'
        );
      });
    });
  }

  // tslint:disable-next-line: typedef
  get authentification() {
    return localStorage.getItem('Token');
  }

  // tslint:disable-next-line: typedef
  getUserId() {
    const userJson = JSON.parse(localStorage.getItem('currentUser') || '{}');
    return userJson;
  }
  // tslint:disable-next-line: typedef
  createChart(
    ben: any,
    haben: any,
    prozent: any,
    chart: any,
    label: any,
    label2: any
  ) {
    const canvas = document.getElementById(chart) as HTMLCanvasElement;
    if (canvas == null) {
      return;
    }
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: 'doughnut',
      text: prozent,
      data: {
        labels: [label, label2],
        datasets: [
          {
            label: '# of Votes',
            data: [ben, haben],
            backgroundColor: ['rgb(200,10,20)', 'rgb(10, 155, 10)'],
            borderColor: ['rgb(200,10,20)', 'rgb(10, 155, 10)'],
          },
        ],
      },
      options: {
        responsive: true,
      },
    });
  }
}
declare var Chart: any;
Chart.pluginService.register({
  beforeDraw: (chart: any) => {
    // tslint:disable-next-line: one-variable-per-declaration
    const width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;

    ctx.restore();
    const fontSize = (height / 300).toFixed(2);
    ctx.font = fontSize + 'em sans-serif';
    ctx.textBaseline = 'middle';
    // tslint:disable-next-line: one-variable-per-declaration
    const text = chart.config.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 1.8;

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
});
