import { FirmaComponent } from './firma/firma.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { PersonalComponent } from './personal/personal.component';
import { LoginComponent } from './login/login.component';

import { ZeigePersonalComponent } from './personal/zeige-personal/zeige-personal.component';
import { ZeigeKurseComponent } from './kurse/zeige-kurse/zeige-kurse.component';
import { AnfrageComponent } from './kurse/anfrage/anfrage.component';
import { ZeigeBuchbarekurseComponent } from './kurse/zeige-buchbarekurse/zeige-buchbarekurse.component';
import { ZeigeGebuchtekurseComponent } from './kurse/zeige-gebuchtekurse/zeige-gebuchtekurse.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { AuditComponent } from './audit/audit.component';
import { HomeComponent } from './home/home.component';
import { TeilnehmerkurseComponent } from './kurse/teilnehmerkurse/teilnehmerkurse.component';
import { AddEditPersonalComponent } from './personal/add-edit-personal/add-edit-personal.component';
import { SelbstverwaltungComponent } from './selbstverwaltung/selbstverwaltung.component';
import { RegistrationComponent } from './registration/registration.component';
import { InterneKurseComponent } from './interne-kurse/interne-kurse.component';
import { VergangeneKurseComponent } from './vergangene-kurse/vergangene-kurse.component';


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'firma', component: FirmaComponent},
  {path: 'personal/mitarbeitende', component: PersonalComponent},
  {path: 'personal/mitarbeitende/:id', component: ZeigePersonalComponent},
  {path: 'personal/mitarbeitende/:id/edit', component: AddEditPersonalComponent},
  {path: 'kurse/buchbar', component: ZeigeBuchbarekurseComponent},
  {path: 'kurse/gebucht', component: ZeigeGebuchtekurseComponent},
  {path: 'kurse/:id', component: ZeigeKurseComponent},
  {path: 'anfrage', component: AnfrageComponent},
  {path: 'kontakt' , component: KontaktComponent},
  {path: 'audit' , component: AuditComponent},
  {path: 'selbstverwaltung/:kursCode/:key/:companykey', component: SelbstverwaltungComponent},
  {path: 'kurse/mitarbeitende', component: TeilnehmerkurseComponent},
  {path: 'registrierung/:startkey', component: RegistrationComponent},
  {path: 'intern/kurse', component: InterneKurseComponent},
  {path: 'vergangenekurse/:id', component: VergangeneKurseComponent},
  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
