<div class="container" *ngIf="KursList.length > 0">
  <div class="columns">
  <div class="column is-two-thirds">
    <div class="table-responsive">
  <table class="table is-hoverable">
      <thead>
          <tr>
            <th>
              Kursansicht
            </th>
              <th>
                KursCode

  </th>
              <th>
                Kurstitel
  </th>
  <th> Datum

      </thead>
      <tbody>
          <tr class="clickable" *ngFor="let dataItem of KursList; index as i">
            <td class="text-center"><button type="button" class="btn btn-outline-secondary btn-sm" [routerLink]="['/kurse/', dataItem.KursID]">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"  viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
              </button></td>
              <td>{{dataItem.KursCode}}</td>
              <td>{{dataItem.Kursname}}</td>
              <td>{{convertDate(dataItem.Datum)}}</td>
  
          </tr>
      </tbody>
  </table>
  </div>
</div>

  </div>
  </div>


  <div class="row" *ngIf=" KursList.length  < 1"> 
    <div class="col">Aktuell stehen keine Erste Hilfe Kurse bevor.</div></div>