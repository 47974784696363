<div class=" container1 py-3 text-center contactButtonClose"  (click)="showForm()" *ngIf="!showContactForm"   [@fadeIn]  style="background-color: {{gliederung?.OL_Navbar_BGColor}}; color: {{gliederung?.OL_Navbar_FontColor}};"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chat" viewBox="0 0 16 16">
    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
  </svg></div>
  <div class=" containe py-3 text-center contactButtonOpen" (click)="showForm()"  *ngIf="showContactForm" [@fadeIn] style="background-color: {{gliederung?.OL_Navbar_BGColor}}; color: {{gliederung?.OL_Navbar_FontColor}};"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chat" viewBox="0 0 16 16">
    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
  </svg></div>
<div *ngIf="showContactForm" [@fadeIn] [@fadeOut] >
    <div class=" shadow p-1"  style=" width: 300px; background-color: rgb(255, 255, 255);" >
        <div class="m-4">
            <div class="row  fw-bold fs-3">Wir sind gerne für Sie da.</div>
            <div class="row py-3" >
              <img src={{gliederung?.OL_PicURL_Header}} >
            </div>
          <div
            class="row pb-3"
            [innerText]="gliederung?.OL_Text_Ansprechpartner"
          ></div>
          <div class="row">{{gliederung?.Organisation}}</div>
          <div class="row" [innerText]="gliederung?.OL_Anschrift_Name"></div>
          <div class="row">{{ gliederung?.OL_Anschrift_Strasse }}</div>
          <div class="row">
            {{ gliederung?.OL_Anschrift_PLZ }} {{ gliederung?.OL_Anschrift_Ort }}
          </div>
          
        </div>
      </div>
    </div>